<template>
  <div class="sign_in">
    <div class="sign_in_top">
      <div class="growth_value">
        <div class="growth_value_lf">
          <p>我的成长值</p>
          <p>{{ growthValue }}</p>
        </div>
        <div>
          <div class="growth_value_ri" @click="ruleClick">
            <van-image
              width="12"
              :src="require('@/assets/img/SignIn_icon_02.png')"
            />
            <!-- <van-icon name="chart-trending-o" /> -->
            <span>成长规则</span>
          </div>
          <div
            class="growth_value_ri"
            @click="$router.push('/signIn/GrowthValueUse')"
          >
            <van-image
              width="11"
              :src="require('@/assets/img/SignIn_icon_01.png')"
            />
            <!-- <van-icon name="orders-o" /> -->
            <span>成长值使用</span>
          </div>
        </div>
      </div>
      <div class="grade">
        <div
          class="round_line"
          :style="'transform:rotate(' + getDeg(grade) + ') translateZ(0)'"
        ></div>
        <div class="emblem">
          <div class="text" v-for="(text, index) in emblemText" :key="index">
            <div
              :class="{ current: index == grade, no_current: index > grade }"
            >
              {{ text }}
            </div>
          </div>
        </div>
      </div>
      <div class="me_growth_value_box">
        <div class="me_growth_value">
          <van-image
            fit="cover"
            position="left"
            :src="wxHeadimgurl || require('@/assets/img/main.jpg')"
          />
          <p>
            今日成长值 <span>+{{ todayGrowthValue }}</span>
          </p>
        </div>
      </div>

      <div class="sign_in_top_schedule">
        <div
          v-for="item in signInList"
          :key="item.ttime"
          class="sign_in_top_val"
        >
          <van-icon v-if="item.count == 1" name="passed" />
          <van-icon v-else name="checked" />

          <!-- <van-image
            v-if="item == 7"
            fit="cover"
            position="left"
            :src="require('@/assets/img/SignIn_01.png')"
          /> -->
        </div>
      </div>

      <div class="sign_in_top_but">
        <van-button type="info" :disabled="disabled" @click="signIn"
          >获取今日成长值</van-button
        >
      </div>
    </div>
    <div class="main_box">
      <div class="sign_in_main">
        <div class="advertise_list">
          <div
            class="advertise_list_box"
            v-for="item in advertiseImgList"
            :key="item.id"
            @click="advertiseClick(item)"
          >
            <van-image
              width="100%"
              fit="cover"
              :src="
                item.advertImage
                  ? item.advertImage
                  : require('@/assets/img/ProductServe_01.jpg')
              "
            />
          </div>
        </div>
        <div class="sign_in_bottom">
          <van-button type="info" @click="goGiftGrowth"
            >领取成长权益</van-button
          >
        </div>
        <copyrightIp />
      </div>
    </div>
  </div>
</template>
<script>
import { Icon, Button, Toast, Image as VanImage, Dialog } from "vant";
import { findAdByLocation } from "@/api/policyLibrary";
import {
  findUserIntegralGarden,
  dailyCheck,
  dailyCheckTheDay,
  theDayGetUpValue,
  getActivityRule,
} from "@/api/SignIn";
import copyrightIp from "@/components/copyrightIp";
const envUrl = process.env.VUE_APP_API_URL;

export default {
  components: {
    [Icon.name]: Icon,
    [Button.name]: Button,
    [VanImage.name]: VanImage,
    [Dialog.name]: Dialog,
    copyrightIp,
  },
  data() {
    return {
      emblemText: ["v1", "v2", "v3", "v4", "v5", "v6", "v7"],
      advertiseImgList: [], //广告列表
      wxHeadimgurl: "", //头像
      grade: "", //等级
      growthValue: 0, // 成长值
      todayGrowthValue: "", // 今日成长值
      disabled: false, //今日是否签到
      signInList: [
        {
          ttime: "星期一",
          count: 0,
        },
        {
          ttime: "星期二",
          count: 0,
        },
        {
          ttime: "星期三",
          count: 0,
        },
        {
          ttime: "星期四",
          count: 0,
        },
        {
          ttime: "星期五",
          count: 0,
        },
        {
          ttime: "星期六",
          count: 0,
        },
        {
          ttime: "星期日",
          count: 0,
        },
      ], //签到打勾列表
      growthValueRule: "", // 成长值规则
    };
  },
  mounted() {
    this.getFindAdByLocation(); // 广告详情
    this.getFindUserIntegralGarden(); // 获取用户成长值 等级信息
    this.getdailyCheckTheDay(); //  检查用户签到状态
    this.getTheDayGetUpValue(); // 今日成长值
    this.getGetActivityRule(); // 成长值规则
  },
  methods: {
    // 成长值规则
    ruleClick() {
      // Dialog.alert({
      //   title: "成长值规则",
      //   message: this.growthValueRule,
      // }).then(() => {
      //   // on close
      // });

      window.location.href = `${envUrl}#/policyLibrary/articleDetails?tessayId=72`;
      // "https://zzxs.xinyanhecheng.com/dist/#/policyLibrary/articleDetails?tessayId=72";
      // "https://wms.ciicpt.com.cn/#/policyLibrary/articleDetails?tessayId=72";
    },
    //  检查用户签到状态
    getdailyCheckTheDay() {
      let openId = localStorage.getItem("code") || "";
      dailyCheckTheDay({ openId }).then((res) => {
        if (res.data.data == "1001") {
          this.disabled = true;
        }
      });
    },
    // 获取用户成长值 等级信息
    getFindUserIntegralGarden() {
      let openId = localStorage.getItem("code") || "";
      findUserIntegralGarden({ openId }).then((res) => {
        this.wxHeadimgurl = res.data.data.twxUser.wxHeadimgurl || "";
        this.grade = Number(res.data.data.twxUser.garden?.split("V")[1]) - 1;
        this.growthValue = res.data.data.twxUser.userUpValue || 0;
        res.data.data.checkDailyVoList.forEach((item) => {
          if (item.count == 1) {
            this.signInList.find((jtem) => {
              if (item.week == jtem.ttime) jtem.count = 1;
            });
          }
        });
        // this.signInList = res.data.data.checkDailyVoList?.reverse();

        // let i = this.signInList.length + 0;
        // while (i < 7) {
        //   this.signInList.push({ count: 0 });
        //   i++;
        // }
        // for (let i = this.signInList.length; i < 7; i++) {
        //
        // }
      });
    },
    // 广告跳转
    advertiseClick(data) {
      if (data.advertUrl) {
        window.location.href = data.advertUrl;
      }
    },
    // 广告详情
    getFindAdByLocation() {
      findAdByLocation({ location: 1 }).then((res) => {
        if (res.data.status == 200) {
          this.advertiseImgList = res.data.data;
          this.advertiseImgList = this.advertiseImgList.sort((a, b) => {
            let aTimeString = a.updateTime;
            let bTimeString = b.updateTime;
            // aTimeString = aTimeString.replace(/-/g, "/");
            // bTimeString = bTimeString.replace(/-/g, "/");
            let aTiem = new Date(aTimeString).getTime();
            let bTiem = new Date(bTimeString).getTime();
            return bTiem - aTiem;
          });

          this.advertiseImgList.length > 2
            ? (this.advertiseImgList.length = 2)
            : "";
        }
      });
    },
    getDeg(index) {
      return index * 22 - 157 + "deg"; //一个圆是360度这里划分成了12份。每个文字角度是30度。然后从第一个文字开始距离水平线是倾斜了15度的
    },
    // 签到
    signIn() {
      let openId = localStorage.getItem("code") || "";
      this.disabled = true;
      let type = "1";
      dailyCheck({ openId, type }).then((res) => {
        this.grade = Number(res.data.data.garden?.split("V")[1]) - 1;
        this.growthValue = res.data.data.userUpValue || 0;
        this.getTheDayGetUpValue();
        this.getdailyCheckTheDay();
        this.getFindUserIntegralGarden();
        Toast("已经领取今日成长值");
      });
    },
    // 今日成长值
    getTheDayGetUpValue() {
      let openId = localStorage.getItem("code") || "";
      theDayGetUpValue({ openId }).then((res) => {
        this.todayGrowthValue = res.data.data;
      });
    },
    // 成长值规则
    getGetActivityRule() {
      getActivityRule().then((res) => {
        this.growthValueRule = res.data.data.activityContent;
      });
    },
    goGiftGrowth() {
      this.$router.push("/SignIn/GiftGrowth");
    },
  },
};
</script>

<style lang="scss" scoped>
.sign_in_top {
  width: 100%;
  padding: 38px 15px;
  position: relative;
  // background-image: linear-gradient(to bottom, #ff9b07, #ffcc99);
  background: #24262e;
  .growth_value {
    width: 100%;
    color: #fff;
    position: absolute;
    top: 15px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    .growth_value_lf {
      background: #f6d497;
      color: #333;
      text-align: center;
      padding: 1px 5px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 0 10px 10px 0;
    }
    .growth_value_ri {
      font-size: 12px;
      background: #65676c;
      border-radius: 15px 0 0 15px;
      padding: 0px 6px;
      line-height: 32px;
      display: flex;
      align-items: center;
      span {
        padding-left: 4px;
      }
      &:nth-child(1) {
        margin-bottom: 8px;
      }
    }
  }
  .grade {
    width: 100%;
    height: 143px;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background: #24262e;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      bottom: -3px;
      left: 43px;
    }
    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background: #24262e;
      border-radius: 50%;
      position: absolute;
      z-index: 10;
      bottom: -3px;
      right: 43px;
    }
    .round_line {
      position: absolute;
      top: 52px;
      width: 270px;
      height: 135px;
      border: 2px solid #e6c891;
      border-radius: 50% 50% 0 0/100% 100% 0 0;
      border-bottom: none;
      transform-origin: center bottom;
      transform: rotate(-157deg) translateZ(0);
      transition: 1s;
      z-index: 1;
    }
    .emblem {
      position: absolute;
      top: 52px;
      width: 271px;
      height: 135px;
      border: 2px solid #3b3d44;
      border-radius: 50% 50% 0 0/100% 100% 0 0;
      border-bottom: none;
      color: #8e9ba2;
      text-align: center;
      .text {
        display: flex;
        justify-content: center;
        position: absolute;
        color: #e6c891;
        left: -6px;
        right: 0;
        top: -34px;
        bottom: 0;
        font-size: 12px;
        transition: all 0.5s cubic-bezier(0, 0, 0, 1);
        transform-origin: 140px 168px;
        &:nth-child(1) {
          top: -32px;
          left: -7px;
        }
        &::before {
          content: "";
          width: 8px;
          height: 8px;
          background: #e6c891;
          border-radius: 50%;
          position: absolute;
          z-index: 10;
          top: 29px;
          left: 134px;
        }

        .current {
          width: 22px;
          height: 22px;
          background: #e6c891;
          border-radius: 50%;
          line-height: 22px;
          color: #24262e;
          font-weight: bold;
          position: relative;
          &::after {
            content: "";
            width: 26px;
            height: 26px;
            border: 1px solid #e6c891;
            position: absolute;
            top: -3px;
            left: -3px;
            border-radius: 50%;
          }
        }
        .no_current {
          color: #fff;
        }
      }
    }
    @for $i from 1 through 7 {
      .text:nth-of-type(#{$i}) {
        transform: rotate(($i - 1) * 22 - 65 + deg) translateZ(0);
      }
    }
  }
  .me_growth_value_box {
    position: relative;
    padding-bottom: 20px;
    .me_growth_value {
      width: 100%;
      text-align: center;
      position: absolute;
      top: -60px;
      left: 0;
      color: #fff;
      .van-image {
        width: 38px;
		height: 38px;
        border-radius: 50%;
        overflow: hidden;
        margin: 10px 0;
      }
      p {
        color: #a3a3a6;
        span {
          color: #e6c891;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .sign_in_top_schedule {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    padding: 25px 0;
    position: relative;

    .sign_in_top_val {
      position: relative;
      .van-image {
        width: 20px;
      }
      &::before {
        content: "";
        width: 30%;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 9px;
        left: 0;
      }
      &::after {
        content: "";
        width: 30%;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 9px;
        right: 0;
      }
      &:nth-child(1) {
        &::before {
          height: 0px;
        }
      }
      &:nth-last-child(1) {
        &::after {
          height: 0px;
        }
      }
      ::v-deep .van-icon-passed {
        font-size: 20px;
        color: #e6c891;
      }
      ::v-deep .van-icon-checked {
        font-size: 20px;
        color: #fff;
      }
    }
  }
  .sign_in_top_but {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    ::v-deep .van-button {
      width: 100%;
      border-radius: 6px;
      background: #e6c891;
      border: 1px solid #e6c891;
      height: 38px;
    }
  }
}
.main_box {
  width: 100%;
  position: relative;
  .sign_in_main {
    width: 100%;
    padding: 10px;
    position: absolute;
    left: 0;
    top: -15px;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
    background: #fff;
    .advertise_list {
      width: 100%;
      background: #fff;
      .advertise_list_box {
        width: 100%;
        height: 140px;
        margin-top: 10px;
        border-radius: 8px;
        overflow: hidden;
        text-align: center;
        img {
          width: 100%;
        }
      }
    }
    .sign_in_bottom {
      width: 100%;
      padding: 10px;
      background: #fff;
      margin-top: 20px;
      ::v-deep .van-button {
        width: 100%;
        border-radius: 6px;
      }
    }
  }
}
</style>
